import React, { Component } from "react";

class Notification extends Component {
  render() {

    return (
        <div className="notification flex-center">
          <div className="container">
            <div className="notification-message">Currently building <a href="https://www.favbox.app/" target="_blank" rel="noopener noreferrer">Favbox.app</a>, the sharing platform app. It´s only available for a few at this point <span role="img" aria-label="hamburguer">🍔</span>, but Hey! You can try!</div>
          </div>
        </div>

    );
  }
}

export default Notification;
