import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Design in a business process.<br></br><span>From customized B2B projects to product philosophy.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m">
          <img src={`${process.env.PUBLIC_URL}/img/projects/hooptap/widget.png`} className="text-center" alt="Hooptap App"/>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The context</div>
          <p>
            Hooptap was a startup focused on providing solutions for engagement. Based on game mechanics and other features, the goal was to increase engaging numbers, and the formula was...
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Business model at the very beginning</div>
          <p>
            At his early stages the startup was <strong>working on his own app</strong> inside a "B2C" business model. Brands could subscribe to in-app campaigns. It was downloadable from the stores and users could win prizes and offers from this brand campaigns. At this time I was freelancing and started collaborating doing UI work, for both Android and IOS platforms.
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">The Golden Age</div>
          <p>
            Soon the <strong>business model pivoted to “B2B”</strong> – means that you are selling a product or service to other businesses. This model of custom B2B apps worked much better. We developed app’s for big companies, and also big brands such Discovery Channel, Nickelodeon, Rtve ClanTv, Heineken, Travel Club, MTS, Teletica, TVI, Canal 13, Danone, MasterChef, Consum.. and a lot of other companies. At this moment the company was closer to “break even”.
          </p>
        </div>

        <div className="fullwidth mt-l text-center">
          <img src={`${process.env.PUBLIC_URL}/img/projects/hooptap/logos.jpg`} alt="Brands & companies" />
          <div className="caption">Some great brands & companies we worked for (Case studies available on demand).</div>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Modular design for business efficiency</div>
          <p>
            But there was a <strong>big problem: scalability.</strong> The team could not make app’s as fast as the business model needed. We started to work on modular components, and also the design evolved this way, and started to build modular design for reuse.  The idea was to integrate a unique back-office environment, but to deliver a custom interface for each business customer. Same tools, same core features and same technologies were tailored to specific clients with custom look and feel, custom workflow, and custom server infrastructure.
          </p>
          <p>
            All the core features were broken into components, the content consumption into different repeatable layouts, and all the ui elements into atoms. I am talking about <strong>design systems and atomic design.</strong>
          </p>
          <p>
            The team worked hard to build a large number of features that included profile status, notification system, recent activity feed, friend network, ranking(s), news feed, reward system (points, badges and prizes), quests, marketplace, second screen experience, voice recognition.. and by the other side a dashboard for content management that we friendly referred as “Skynet” <span role="img" aria-label="robot">🤖</span>.
          </p>
        </div>




        <div className="container container-xs mt-l">
          <div className="label">Product philosophy, API & SDK’s</div>
          <p>
            No matter how fast you release new features, you’ll never be able to meet the needs of every single customer. But having an API gives your customers (and their development partners) the freedom to customize your software and build the exact solution they need today, not when you release the next version of your software.
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Mockup's, prototypes & live demos</div>
          <p className="text-bold">
            Internally, it can be hard to show progress.
          </p>
          <p>
            This might not be an issue in all companies, but it was in our case. Executives love demos to see progress, and these demos are usually based around the product’s user interface.  The challenge with APIs is that they don’t have a UI—it’s just code.  And for most people, it’s hard to see progress just by looking at code.
          </p>
          <p>
            So we decided to mockup a sample using the new javascript sdk that was connected to the new API. After some validations, research and benchmarking <strong>the challenge was to make a web-based widget that included all the main core features.</strong>
          </p>
          <p>
            We had all the features (almost) ready, and our user workflows were defined, so I started to design all the elements (atoms), components, templates and screens.
          </p>
        </div>



        <div className="fullwidth mt-l text-center pt-m pb-m bg-soft-grey">
            <img src={`${process.env.PUBLIC_URL}/img/projects/hooptap/widget-screens.png`} alt="First Prototype" />
            <div className="caption">UI sample of main screens and interface elements.</div>
        </div>



        <div className="container container-xs mt-l">
          <p>
            Then started prototyping to test meaningful interactions, behaviours, and for product testing pourposes. The idea was well received, and the marketing team was interested in exploring more the possibilities of this demo.
          </p>
        </div>



        <div className="fullwidth mt-l text-center pt-m pb-m">
            <img src={`${process.env.PUBLIC_URL}/img/projects/hooptap/prototype.gif`} alt="First Prototype" />
            <div className="caption">Early stage Principle prototype showing basic interactions.</div>
        </div>



        <div className="container container-xs mt-l">
          <p>
            So we began to code a live sample using the new sdk, and api. It was based on javascript, and implemented a simple template system based on underscore.js to manage views and data. Technically it was nothing fancy, but the live prototype was superuseful for testing behaviours, and developing UI and microinteractions.
          </p>
          <br></br>

          <video muted autoPlay loop>
            <source src={`${process.env.PUBLIC_URL}/img/projects/hooptap/microinteractions.mov`} type="video/mp4" />
          </video>
          <div className="caption">HTML & JS live prototype for testing pourposes.</div>
          <br></br>

          <p>
            Then implemented more complex features, such login. This allowed the team to test and get feedback about implementation issues related to sdk complexity.
          </p>

          <br></br>

          <video muted autoPlay loop>
            <source src={`${process.env.PUBLIC_URL}/img/projects/hooptap/login.mov`} type="video/mp4" />
          </video>
          <div className="caption">HTML & JS prototype for testing & validation pourposes.</div>
        </div>

        <div className="container container-xs mt-l">
          <p>
            Finally we implemented the widget inside a website and demonstrated the capabilities of the SDK and the API. You can trigger any action, like click, visit a page, scroll, etc and call the API to send and return data.
          </p>
          <p>
            We worked, just for testing and validation pourposes, on a ficticious client which has an ecommerce inside entertainment/music industry. Our client is interested in rewarding key actions to his visitors, so we defined a strategic plan to engage the user.
          </p>
          <p>
            We defined user journeys and targeted key user actions related to content and others related to core features:
          </p>
          <ul>
            <div className="label mt-s">Content related</div>
            <li>Visit a section (doAction=>Visit)</li>
            <li>Add an item to cart (doAction=>AddToCart)</li>
            <li>Buy an item (doAction=>Buy)</li>
            <li>Rate content (doAction=>Rate)</li>
            <li>Interact with a blog post (doAction=>Read)</li>
          </ul>
          <ul>
            <div className="label mt-s">API & SDK related</div>
            <li>Register and fill your profile</li>
            <li>Return and login</li>
            <li>Earn points, win badges, upgrade levels...</li>
            <li>Join a Quest, Complete Steps, Finish Quests</li>
          </ul>
        </div>


        <div className="fullwidth mt-l text-center pt-m pb-m bg-soft-grey">
          <div className="container">
            <video muted autoPlay loop width="100%">
              <source src={`${process.env.PUBLIC_URL}/img/projects/hooptap/ecommerce-demo.mp4`} type="video/mp4" />
            </video>
            <div className="caption">SDK integration on a live demo website. Testing & validation.</div>
          </div>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">Key learnings</div>
          <p>
            Startups are hard. We made a bunch of great apps, but the latest product here described never made it into the hands of our customers. I'm proud of the work the team did and grateful for the opportunity. I'd like to specially thank the dev team for all of their lessons, for sharing their knowledge and for helping me to shape as the designer I am today.
          </p>
        </div>


      </div>

    </div>

    <Footer />
    <Banner />

  </div>

  );
}
export default content