import React, { Component } from "react";

class Banner extends Component {
  render() {
    return (

        <div className={`banner mt-s ${this.props.class || ''}`}>
          <div className="container flex-justify">
            <h3 className="cl-white">{this.props.message || 'Get in touch today!'}</h3>
            <div className="email flex-justify">
              <span>I love emails.</span>
              <a className="button-small-outline ml-s" href="mailto:carlosmarchinfo@gmail.com" target="_blank" rel="noopener noreferrer">
                Email me <span role="img" aria-label="email">✨</span>
              </a>
            </div>
          </div>
        </div>

    );
  }
}

export default Banner;
