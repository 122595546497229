import React, { Component } from "react";

import Header from '../components/header';
import Banner from '../content/banner';
import NotFound from '../components/notfound';

class NotfoundView extends Component {

  render() {

    return (

      <div className="app-wrapper  theme-light page-404">
        <Header socialListClass="no@m" status={this.props.status}/>
        <div className="global">
          <div className="vh100 dl-grid">
            <div className="container">
              <NotFound/>
            </div>
            <Banner class="flex-end"/>
          </div>
        </div>
      </div>

    )

  }

}

export default NotfoundView;
