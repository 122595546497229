import React, { Component } from "react";
import { Link } from 'react-router-dom';

class NotFound extends Component {

  constructor(props) {
    super(props);
    this.state = {
      img: `${process.env.PUBLIC_URL}/img/draw-2.png`
    };
  }


  render() {

    return (
        <div className="notfound text-center">
          <div className="notfound-icon">
            <img src={this.state.img} alt="Draw of me" className="max-h-m"/>
          </div>
          <h4 className="notfound-title alt-font mt-m">Oh S#*t!</h4>
          <p className="notfound-text pb-s cl-grey big-text">
            I've lost that project...
            <br></br>
            Please take a look at some
            <Link to="/" className="underline-hover bold" onMouseEnter={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-1.png` }) }} onMouseOut={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-2.png` }) }}> works </Link>,
            read more
            <Link to="/about" className="underline-hover bold" onMouseEnter={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-4.png` }) }} onMouseOut={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-2.png` }) }}> about me </Link>,
             or
            <a href="mailto:carlosmarchinfo@gmail.com" target="_blank" rel="noopener noreferrer" className="underline-hover bold" onMouseEnter={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-3.png` }) }} onMouseOut={() => { this.setState({ img: `${process.env.PUBLIC_URL}/img/draw-2.png` }) }}> get in touch</a>.
          </p>
        </div>

    );
  }
}

export default NotFound;
