import React, { Component } from "react";

import {ReactComponent as CloseIcon} from '../icons/close.svg';

const statusController = require('../controllers/helpers.js');

class Status extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }


  componentDidMount(){
    window.addEventListener('scroll', () => {
       if(window.scrollY > window.innerHeight/2 ){
          if(statusController.showBanner()){
            console.log(statusController.showBanner())
            this.setState({ isVisible: true });
          }

       }else{
          this.setState({ isVisible: false });
       }

    });
  }

  handleLike = (e) => {
    e.preventDefault();
    statusController.closeStatusUI();
    this.setState({ isVisible: false });
  }



  render() {

    return (
        <div className={`status flex-center show-${this.state.isVisible}`}>
          <div className="profile-image-holder">
            <div className="profile-status"></div>
          </div>
          <div className="profile-text ml-xs">
            <span className="bold flex profile-text-headline alt-font">{this.props?.status}</span>
            <span className="profile-text-body">Hi! I´m open to enroll in new product design challenges. <a className="underline" href="mailto:carlosmarchinfo@gmail.com">Let´s talk?</a></span>
          </div>
          <CloseIcon className="close-icon" onClick={this.handleLike}/>
        </div>

    );
  }
}

export default Status;
