import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Designing a foodie App.<br></br><span>Methods, theories and principles.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m">
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/@gh-home.png`} className="text-center" alt="Guia Hedonista"/>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/@gh-detail.png`} className="no@m" alt="Guia Hedonista"/>
        </div>
        <div className="container container-xs mt-l">
          <div className="label">Intro</div>
          <p>
            I would like to use this project to write about design, methods and principles, and at the same time treat it as a case study. Because writing helps me to create a “thesaurus” of topics that interest me, and helps me to think and communicate more clearly.
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The Context</div>
          <p>
            With the will to tell the life of the gastronomy of the Valencian Community, its cookers, restaurants, product and, much more importantly, to bring it closer to people, the digital magazine specialized in gastronomy "La Guía Hedonista" decides to move to mobile environments with an app experience.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/app.png`} alt="First Prototype" className="pt-m" />
          <div className="caption mb-m">Home, Map and Detail views of the <a href="https://dribbble.com/shots/3170827-App-Guia-Hedonista" target="_blank" rel="noopener noreferrer">IOS App</a></div>
          <p>
            In collaboration with the head of the publication and art director <a href="https://twitter.com/nadaimporta" target="_blank" rel="noopener noreferrer">@Nada Importa</a>, the VG team, stakeholders, and along with a series of requirements, I helped identify the needs and answer the questions that emerged during the conceptualization of the app. As a product designer, I led the process of designing the application for IOS and Android as well as the concept of a unified cross-platform design language.
          </p>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">The Concept</div>
          <p className="text-bold">
            Reformulating the strategy to create a foodie app.
          </p>
          <p>
            The project has reformulated the content strategy from the digital magazine and created a digital product to better guide our users and attract them to know a gastronomy that is in an overwhelming moment.
          </p>
        </div>




        <div className="container container-xs mt-l">
          <div className="label">Understanding the user (and empathizing with)</div>
          <p className="text-bold">
            Making design decisions is not easy, but if you know your users is much easier to design and validate those decisions.
          </p>
          <p>
            In order to think about all the different possibilities of use, I helped identify potential users, situations, motivations and goals to achieve.
          </p>
          <ul>
            <li>
              <strong>Persona #1.</strong> Laura, 38. Plans his weekends. She seeks and is not carried away by the spontaneous. It is advised by the best. You will read the review and would probably call the restaurant to book from the app if you could. Every detail is a small pleasure that squeezes to the maximum. He will delight in criticism and just thinking about it makes his mouth water.
            </li>
            <li>
              <strong>Persona #2.</strong> Ramón, 31. Foodie. He likes to discover, but thanks an expert filter. It will do you good to know what's nearby for that one-night midweek dinner. Its main motivation is to enjoy with its passion for gastronomy, to leave the routine and the conventional. He goes in search of the city to surprise and excite him, and he will not stop investigating new options for it.
            </li>
            <li>
              <strong>Persona #3.</strong> Paula, 34. Urbanite. Works and eats every day away from home. He takes care of himself and usually cooks the day before, but sometimes he prefers to pass by a restaurant near his workplace to disconnect and eat something decent.
            </li>
          </ul>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Defining a point of view</div>
          <p className="text-bold">
            The mapping stage helps to have a frame, an ideal photo of the possible use cases, to create the user history.
          </p>
          <p>
            The search and definition of our users defines many of these possible scenarios. It is very useful to define the scenario by introducing the context and presenting it with a focus on the cause, trying to define the motivations and expectations
            <a href="https://jtbd.info/replacing-the-user-story-with-the-job-story-af7cdee10c27" target="_blank" rel="noopener noreferrer">– This reading is essential</a>. For example in our case:
          </p>
          <ul>
            <li>As a foodie, I want to discover a restaurant, to enjoy with my friends a different experience.</li>
            <li>As a person who enjoys good gastronomy, I want recommendations for an ideal restaurant, to go out with my partner to enjoy a special dinner.</li>
            <li>As an urbanite who eats away from home, I want to eat well, with my coworkers at a nearby restaurant that doesn't fail.</li>
          </ul>

        </div>


        <div className="container container-xs mt-l">
          <div className="label">Detecting needs</div>
          <p className="text-bold">
            Each of these issues provides a solid hypothesis of how our users flows should be.
          </p>
          <p>
            And more interesting is that through the users and their contexts and histories, we detect a series of needs that help us to manage and define the architecture and the characteristics that the application should have. For example:
          </p>
          <ul>
            <li>Knowing a restaurant through a review → Need: Curated content, Clear reading</li>
            <li>Discovering restaurants that fit my tastes → Need: Filters and Categories + discovery factor</li>
            <li>Choosing a restaurant when you are on the street and want to find something nearby → Need: Map and Geolocation</li>
            <li>Contacting restaurants for your reservation → Need: Specific data and related actions</li>
          </ul>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The Design Challenge</div>
          <p className="text-bold">
            Perhaps the most critical part (and one of the biggest challenges) of the Design thinking process.
          </p>
          <p>
             It will require the ability to synthesize observations, hypotheses and define the problem that the designer will focus on solving.
          </p>
          <p>
            Ideally following a user-centered philosophy, <strong>it will have to respond to user needs</strong> but we can't forget that <strong>it must also respond to business needs.</strong> A good definition will guide the designer and his team, and will help to start the process of ideation in the right direction.
          </p>
          <i>
            How could we bring gastronomy closer to our users? How could we find the ideal restaurant?
          </i>
          <p>
            The idea that we can know and find an ideal restaurant, either through the recommendations of experts or through location, is one of the project basics, always bearing in mind principles such as simplicity, honesty and aesthetics.
          </p>
          <p>
            Following a user-centered design philosophy that validates the strength of the proposals, adheres to the fundamental principles of design and gives content - to words and people - the importance they deserve, was at all times one of the main challenges, and what was going to help define the structure of the application.
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">  Wireframes and prototypes</div>
          <p className="text-bold">
            Starting from the previous hypotheses, we began the design stage.
          </p>
          <p>My work started by getting this wireframe.</p>

          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/first-prototype.jpg`} alt="First Prototype" className="pt-m" />
          <div className="caption mb-m">First wireframe provided at a very early stage.</div>

          <p>
            Taking this first wireframe, along with the hypotheses and the framework that we had defined, plus fundamental design principles and standards, <strong>I began to design higher level wireframes</strong> with which quickly validate structure, flows and usability.
          </p>
        </div>


        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/first-wireframes.png`} alt="First Wireframes" />
            <div className="caption">First wireframes with indicators and annotations.</div>
        </div>



        <div className="container container-xs mt-l">
          <p>
          I've always thought that  <strong>design is very good at faking things.</strong> The use of wireframes and clickable prototypes allows the team to prioritize their roadmap, and obtain feedback in earlier stages. This creates an environment for reporting on design decisions without the need to invest too much time and resources. If you have an idea, you can <strong>prototype in a couple of hours, test and validate, and repeat, creating a methodology</strong> very similar to what we mean by scientific method <span role="img" aria-label="scientific">👩‍🔬 </span>
          </p>
        </div>

        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/first-prototypes.png`} alt="First prototypes" />
            <div className="caption">First prototypes testing interaction, and content digest in main view</div>
        </div>


        <div className="container container-xs mt-l">
          <p>
            Pprototyping and testing, report problems in advanced stages, and that's without any doubt a great advantage.
          </p>
          <blockquote>
            If a picture is worth 1000 words, a prototype is worth 1000 meetings. <b>— Tom & David Kelley</b>
          </blockquote>

          <div className="label">The Problem</div>
          <p>
            In this stage <strong>a problem was detected</strong>, however small it seemed, it created confusion in terms of interaction. More than confusion it was a mental map: a behavior assumption. In the screen where you select the filters, should the back button apply the selection?
          </p>
        </div>


        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/filter-view.png`} alt="Filter View" />
            <div className="caption">Problems detected in the Filters view</div>
        </div>

        <div className="container container-xs mt-l">
          <p>
            A big percentage of people simply wanted to return to a previous state, to the security of the main view, others would reset filters and then return, in general users were assuming that they were returning without knowing if selection was applied. It wasn't understood.
          </p>
          <blockquote>
            One of the basic needs we all share is that everyone needs to understand. <b>— Essential design principles WWDC 2017</b>
          </blockquote>
          <p>
            When I detected this problem and after testing and studying about mental maps, I decided to take a solution: emphasize the idea of closing, as the opposit to returning to an earlier state. Changing the back icon "←" for a close "X", meant closing, and not returning. In addition we added a "reset" of the filters and an "indicator" when there was some filter running in the main view.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/back-close.jpg`} alt="Filter View" className="pt-m" />
          <div className="caption mb-m">Back or Close? Apply or cancel? Before and After.</div>
          <p>
            A small change and the result was quite satisfactory, a simple change of icon and we eliminated ambiguities, confusion and the most important was that we amplified its use.
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Solutions and development times</div>
          <p>
            I like the maps and location was another important app feature <span role="img" aria-label="downside">🙃 </span>, so facing this view's design was a challenge for me. After several proposals, the idea of a layer above the map, with the restaurants ordered according to the proximity to your location, fitted from the first moment.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/list-ios-interaction.gif`} alt="Interaction" className="pt-m" />
          <div className="caption mb-m">Primitive version with vertical listing showing Main View, Detail, and Map.</div>
          <p>
            The idea is simple, your position appears in the center and around all the nearby restaurant markers, when you click on one, the card layer focuses on the selected item.
          </p>
          <blockquote>
            Design is never done. <b>— Luke Wroblewski</b>
          </blockquote>
          <p>
            Without being the perfect proposal, it worked and adapted to mental maps of interaction.It was the proposal that best fitted times of development and validation, so we decided to go on with this solution. In addition, can be said that <strong>solves a great insight as is the discovery factor.</strong>
          </p>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Defining a language</div>
          <p className="text-bold">Documenting every design decision.</p>
          <p>
            I have as a principle to document every design decision I make. I try to register every change, every decision, every new solution. This is useful to inform the whole team and stakeholders, it also maintains a state of design that facilitates monitoring, understanding and its evolution. In the same way, I try to <strong>define a design language</strong> that facilitates its implementation. It also helps, above all, to create an environment that facilitates the creation of new solutions with less investment of time.
          </p>
          <blockquote>
            Design holistically for processes and systems, understand behaviors and user needs, and include participatory design in the process. <b>— John Chris Jones</b>
          </blockquote>
          <p>
            In this case the design system takes care of the culture and philosophy of the project, giving importance to words, to people and their dishes, their photos and their names, going down to branding and documenting libraries of components that, without being too extensive, define a design language. 4 colors, 2 fonts, a system of 8pt, atoms, repeatable components and blocks, space and white-space, image and iconography, interface cpywriting and tone, and a few more things, are included to allow creating more screens with the least effort and intuition possible. Trying to keep things simple, intuitive and aesthetic.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/design-system.png`} alt="Design System" className="pt-m" />
          <div className="caption mb-m">Style guide sample.</div>
          <blockquote>
            The Style Guide serves as a vehicle to help people through different disciplines, to share knowledge and to collaborate with each other. <b>- Brad Frost</b>
          </blockquote>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Building a Personal Voice</div>
          <p className="text-bold">Letting photography and iconography lead.</p>
          <p>
            Photography is super important to the app experience. Letting the photo lead, it gives the user interface a bold and tidy appearance, and makes navigation from master to detail easy and comfortable. In addition the great iconography created by <a href="https://twitter.com/calpur100">@Calpurnio</a> to represent the categories. The result is a voice that acts with great personality.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/calpurnio-icons.png`} alt="Design System" className="pt-m" />
          <div className="caption mb-m">Calpurnio iconography.</div>
        </div>




        <div className="container container-xs mt-l">
          <div className="label">No more Lorem Ipsum, please</div>
          <p className="text-bold">
            I try to always design with the context in mind - how important the context is.
          </p>
          <p>
            Designing with real data is a priori a highly recommended process, there is no doubt that the context you face is real, and you also grow in communication with the team of engineers <span role="img" aria-label=";)">😉</span>, because you are going to use the information in a very similar way. In addition we can test prototypes with real data, and validate if design solutions are prepared for scalability, repetition, etc. In general is a process involving the work of different teams - communication, development, design .. It is a win-win without a doubt.
          </p>
          <p>
            With a simple json, from Sketch + InVision Craft you can make a request to the server or upload it directly from a local file, and insert the data into the design <span role="img" aria-label="explosion">⚡💥️</span>.
            Here's a demo:
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/json-data.gif`} alt="Json data" className="pt-m" />
          <div className="caption mb-m">Inserting data in sketch from a json via Craft plugin.</div>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">Principles</div>
          <p className="text-bold">Usefulness, honesty, simplicity and aesthetics</p>
          <p>
            In the end I'm pretty satisfied with how it all worked out. The app is consistent and fits into a perfect balance between utility, simplicity and aesthetics. Honesty comes from the critics and their opinion, personal, subjective and absolutely insobornable. Photography and iconography lead and bring personality, the interaction is worked to be intuitive as well as effective, and the team has been satisfied with the results.
          </p>
          <blockquote>
            Design principles are universal: truths contrasted a thousand times and tell us what works. <b>- Javier Cañada</b>
          </blockquote>
        </div>


        <div className="fullwidth mt-l text-center pt-m pb-m">
            <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/final-app.png`} alt="Final App" />
            <div className="caption">Final IOS app look and feel.</div>
        </div>




        <div className="container container-xs mt-l">
          <p>
            There are many ways to understand, focus and solve design problems, to define a point of view. This case study is just an example with which I wanted to discuss theories, methods and principles that interest me and use them to deal with specific details.
            <br></br><br></br><br></br>
            I hope this case study can be helpful, meanwhile you can download on the
            <a href="https://itunes.apple.com/us/app/guia-hedonista/id1185923389">AppStore</a> or
            <a href="https://play.google.com/store/apps/details?id=com.vgagenciadigital.guiahedonista">Google Play</a>

          </p>
        </div>

      </div>

    </div>

    <Footer />
    <Banner />
  </div>

  );
}
export default content