import React, { Component } from "react";
import history from '../controllers/history';

import HeaderBack from '../components/headerBack';
import Protected from '../components/protected';
import NotFound from '../components/notfound';
import Banner from '../content/banner';

import * as projectController from '../controllers/projectController';
import * as helpers from '../controllers/helpers';

class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName   : helpers.getUrlProjectName()
    };
  }


  componentDidMount(){
    window.scrollTo(0, 0)
    //Clear history state messages
    if (history.location.state && history.location.state.message) {
        let state = { ...history.location.state };
        delete state.message
        delete state.type
        delete state.isAuth
        history.replace({ ...history.location, state });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(history?.location?.state && history?.location?.state?.isAuth){
      this.setState({isAuth: true })
    }
  }

  renderTemplate(projectName){
  	return projectController.getProjectData(projectName).template
  }


  render() {

    return (
      <div className={`app-wrapper page-detail theme-${this.props.theme}`}>

        <HeaderBack status={this.props.status} />

        <div className="global">


            { projectController.getProjectData(this.state.projectName) === 'notfound'
              //IS NOTFOUND
              ? <div className="vh100 dl-grid"><div className="container"><NotFound/></div><Banner class="flex-end"/></div>
              //EXISTS. Not protected or auth
              : !projectController.getProjectData(this.state.projectName).isProtected || this.state?.isAuth
                  ? //GET PROJECT
                    this.renderTemplate(this.state.projectName)
                  : //IS PROTECTED OR NOT AUTH. GET PROTECTED COMPONENT
                    <div className="container mt-xl"><Protected projectName={this.state.projectName}/></div>
            }


        </div>

      </div>
    );
  }
}

export default Detail;
