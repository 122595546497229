import React from "react";
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga4';
import history from './controllers/history';
//import './css/index.css';
import './scss/index.scss';

import App from './views/App';
import Detail from './views/Detail';
import About from './views/About';
import NotfoundView from './views/NotfoundView';

import Notification from './components/notification';
import Status from './components/status';

import * as serviceWorker from './serviceWorker';


//Analytics
ReactGA.initialize([{trackingId: "G-VWX06B0QF1"}]);
history.listen(location => {
  ReactGA.send({ hitType: "pageview", page: location.pathname });
});

class Index extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      theme             : 'light', //light || dark || console.log( this.checkThemePreference() )
      showNotification  : false,
      showStatus        : false, //Floating banner
      status            : "Let's talk"// Ex. Open to work or leave empty '' for no status pill
    };
  }


  render() {
    return (
      <Router history={history}>

          {this.state.showNotification ? <Notification /> : ''}

          <Switch>
            <Route exact path="/" component={() => <App theme={this.state.theme} status={this.state.status} />} />
            <Route exact path="/about" component={() => <About theme={this.state.theme} status={this.state.status} />} />
            <Route exact path="/project/:id" component={() => <Detail theme={this.state.theme} status={this.state.status} />} />
            <Route component={() => <NotfoundView theme={this.state.theme} status={this.state.status} />} />/>
          </Switch>

          {this.state.showStatus ? <Status status={this.state.status} /> : ''}


      </Router>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));

serviceWorker.unregister();
