import React from "react";
import Banner from '../content/banner';


const content = () => {
  return (
    <div className="project-detail">

      <div className="container">

        <div className="project-detail-content grid vh100">
            
            <div className="grid__item width-3/12 width-12/12@m no@m"></div>
            <div className="grid__item width-6/12 width-12/12@m pt-m pb-m">
              <h3 className="project-detail-title text-center">
                Koa Health.<br></br><span>Initiatives to make life easier for design team folks 🦄.</span>
              </h3>
              <br></br>
              <div class="cl-grey text-center">
               2020 - 2022 · Product designer at Koa Health
              </div>
            </div>


            <div className="grid__item width-12/12 width-12/12@m flex p-l br-l bg-koaHealth">
              <div className="flex-center centered w-100">
                <img className="mx-h-300" src={`${process.env.PUBLIC_URL}/img/projects/koa/koa-ilu.png`} alt ="koa"/>
              </div>
            </div>

            <div className="grid__item width-3/12 width-12/12@m no@m"></div>

            <div className="grid__item width-6/12 width-12/12@m pt-m">
              <div className="project-detail-text">
                <p>
                  <span className="label">The Context</span>
                </p>
                <p className="text-bold">
                  When I joined Koa Health the design team was <strong>growing at fast pace</strong>. We had 10+ designers in the organisation and having a designer’s house prepared to scale fast and efficiently started to become a thing.
                </p>
                <p>
                  It's about establishing standards for all those real process-heavy elements like the project structure, the file structure, component naming conventions and structure, auto layout and naming of layers. It's not about adding extra layers of complexity or processes, but to <strong>remove layers of uncertainity</strong>.
                </p>
                <p>
                  I really love nerding out about that. Most designers are rarelly interested in things like file organisation and structure. So I jumped into that and <strong>started a series of small initiatives</strong> that impacted lots of tasks in the designer's every day work-life.
                </p>
                
                <p className="pt-l">
                  <span className="label">Design Advocacy/Ops Initiatives</span>
                </p>



                <p className="text-bold">
                  File Covers                
                </p>
                <div className="fullwidth mt-s text-center bg-koaHealth">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/koa/koa-filecovers-tags.png`} alt="Koa File Covers" />
                </div>
                <div className="caption mb-s">A set of covers for organizing the workspace.</div>
                <p>
                  Also proposed ways to improve the organization of design files internally by defining a set of <strong>"File Covers"</strong>.
                </p>
                <p>
                  The set included:
                  <ul>
                    <li>A workflow defining how to use it and when to update the cover</li>
                    <li>Environment identifier (App, WebApp, Specific product...)</li>
                    <li>Title</li>
                    <li>Status</li>
                    <li>Initial date</li>
                    <li>JIRA ticket number</li>
                    <li>Owner</li>
                  </ul>
                </p>
                <p>
                  With File Covers the <strong>goal</strong> was to bring structured logic at workspace level. By bringing upfront the relevant information we reduced bot only the time spent searching by different teams, specially engineers, but also the times each designer "open & closes" a file while searching. 
                </p>




                
                <p className="text-bold pt-l">
                  Boilerplate Starter Template                
                </p>
                <div className="fullwidth mt-s text-center pb-s bg-koaHealth">
                  <div className="container container-xs">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/koa/koa-fileorg.png`} alt="Koa Starter Template" className="has-shadow mx-h-500 br-s mt-m" />
                  </div>
                </div>
                <div className="caption mb-s">A set of pages to kickstart your projects!</div>
                <p>
                  Team processes are key for having crossfunctional aligned teams. The same way having organized deliverables makes it easy to digest for other team members, that's why I researched on best practices and created <strong>"Starter Template"</strong>, a set of pages to kickstart your projects!. 
                </p>
                <p>
                  The <strong>goal</strong> was to bring some structured logic into the chaos of individuals feature files. And it was used systematically in order to have a repeatable, scalable, shareable, easy to digest and organized file structure.
                </p>



                <p className="text-bold pt-l">
                  Micro Workshops               
                </p>
                <div className="fullwidth mt-s text-center pb-s bg-koaHealth">
                  <div className="container container-xs">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/koa/koa-file-microcourse.png`} alt="Koa Autolayout MicroCourse" className="has-shadow mx-h-500 br-s mt-m" />
                  </div>
                </div>
                <div className="caption mb-s">Autolayout workshop</div>
                <p>
                  When figma released autolayout, I spent some time understanding how it worked, and amazed by the potential I shared with the team the benefits and decided to migrate all the design files to work with autolayout.
                </p>
                <p>
                  With this initiative the <strong>goal</strong> was to have all the team in the same page so I organized a <strong>series of mini-workshops</strong> for the team members interested in digging more into autolayout.
                </p>


                

                

                <p className="text-bold pt-l">
                  Contribution Commits               
                </p>
                <div className="fullwidth mt-s text-center bg-koaHealth">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/koa/koa-filecommit.png`} alt="Koa File Commit" className="has-shadow mx-h-500 br-s mt-m mb-m"/>
                </div>
                <div className="caption mb-s">Example of comments best practices</div>
                <p>
                  We as designers in tech have a lot to learn from the engineering teams, and over the years product designers has adopted many engineering best practices. Specially when it comes to shared artifacts that need to be updated by different individuals. If you’re working with collaborators, it’s likely designs in the main file will change and evolve. 
                </p>
                <p>
                  By <strong>commenting updates on shared libraries</strong>, contributors can inform the team about the specifics of the updates. That way, you can acknowledge but also important to revert to certain milestones if needed.
                </p>
                <p className="mb-l">
                  The <strong>goal</strong> was to keep an updated contribution log, and to moveone step forward towards team transparency by informing the folks that are not on top of every update. This workflow also captured some common considerations found within multiple design systems and can be used as a checklist when accepting contributions to your libraries.
                </p>



                <blockquote className="mt-l">
                  <p className="text-bold">
                    As long as we started to see small efficiencies compound, we knew we were moving in the right direction.
                  </p>
                </blockquote>

                <p>
                As we continue to evolve, my hope is to reach a stage where tools and process fade into the background, and we work in a environment where information is easy to find, tools make your daily tasks easy, and collaborating is a friction-less process — and <strong>I look back and wonder how we did it before</strong>.
                </p>



                

              </div>
            </div>

        </div>
      </div>

      <Banner/>

    </div>
  );
}
export default content