import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Headernav from './headerNav';

class Header extends Component {

  render() {

    return (
        <header className={`header flex-center ${this.props.class || '' }`}>
          <div className="container flex-justify">
            <Link to="/" className="header-logo alt-font">Carlos March</Link>
            <Headernav status={this.props.status}/>
          </div>
        </header>

    );
  }
}

export default Header;
