import React, { Component } from "react";

class SocialList extends Component {
  render() {
    return (

      <nav className={`header-menu ml-m ${this.props.className ? this.props.className : ''}`}>
        <ul className="header-menu-nav flex">
          <li><a href="https://www.linkedin.com/in/carlosmarchgas/" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Linkedin</a> <span> · </span> </li>
          <li><a href="https://twitter.com/carlosmarch__" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Twitter</a> <span> · </span> </li>
          <li><a href="https://www.pinterest.es/cmrch" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Pinterest</a> <span> · </span> </li>
          <li><a href="https://medium.com/@carlosmarch__" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Medium</a> <span> · </span> </li>
          <li><a href="https://dribbble.com/carlosmarch" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Dribbble</a> <span> · </span> </li>
          <li><a href="https://github.com/carlosmarch" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Github</a> <span> · </span> </li>
          <li><a href="https://favbox.netlify.app/profile/Carlos%20March" target="blank" className="header-menu-nav-item" rel="noopener noreferrer">Favbox</a><div className="badge">beta</div></li>
        </ul>
      </nav>

    );
  }
}

export default SocialList;
