import React from "react";
import Banner from '../content/banner';
import Snackbar from '../components/snackbar';


const content = () => {
  return (
    <div className="project-detail">

      <div className="container">

        <div className="project-detail-content grid vh100">

            <div className="grid__item width-5/12 width-12/12@m flex bg-ryanair p-l br-l">
              <div className="circle-logo">
                <img src={`${process.env.PUBLIC_URL}/img/projects/ryanair/ryanair-logo.svg`} alt ="ryanair"/>
              </div>
            </div>

            <div className="grid__item width-1/12 width-12/12@m no@m"></div>

            <div className="grid__item width-6/12 width-12/12@m pt-m">
              <h3 className="project-detail-title">
                Ryanair.<br></br><span>Data Driven Design.</span>
              </h3>
              
              <div class="project-title-in">
                <br></br>
                <span class="cl-grey">
                  2019 · Product designer at Ryanair
                </span>
                <Snackbar type={"info mt-s"} message={"💡  This work is restricted under NDA"}/>
              </div>

              <div className="project-detail-text">
                <p>
                  <span className="label">The Context</span>
                  I've been travelling around Europe since I was a child. I enjoy working within a distributed product team and value the power of data as an insight provider for decision making. That´s why I joined Ryanair Design Team.
                </p>
                <p>
                  <span className="label">My Role</span>
                  During the time spent, my squad was responsible of the main booking flow. Every decission we made had impact in millions of customers. We pushed for transparency and consistency while working on the 3.0 massive redesign.
                </p>
                <span className="label">Main goals</span>
                <ul>
                  <li>Drastically reduced bounce rate on Flight Summary Page.</li>
                  <li>Improved the understanding of restrictive bags policy.</li>
                  <li>Increased the overall conversion of higher fares through improving fare understanding.</li>
                  <li>Contributed defining the Design System.</li>
                  <li>Reduced uncertainity about proposed solutions by working under a highly data driven oriented design framework, based on qualitative and quantitative insights.</li>
                </ul>
              </div>
            </div>

        </div>
      </div>

      <Banner/>

    </div>
  );
}
export default content