import React from "react";
import Banner from '../content/banner';
import Snackbar from '../components/snackbar';

const content = () => {
  return (
    <div className="project-detail">

      <div className="container">

        <div className="project-detail-content grid vh100">

          <div className="grid__item width-5/12 width-12/12@m flex bg-cepsa p-l br-l">
            <div className="circle-logo">
              <img src={`${process.env.PUBLIC_URL}/img/projects/cepsa/cepsa.svg`} alt="cepsa"/>
            </div>
          </div>

          <div className="grid__item width-1/12 width-12/12@m no@m"></div>

          <div className="grid__item width-6/12 width-12/12@m pt-m">
            <h3 className="project-detail-title">
              CEPSA.<br></br><span>Helping employees to have a focused intranet.</span>
            </h3>
            <div class="project-title-in">
              <br></br>
              <span class="cl-grey">
                2019 · Design consultant at The cocktail for CEPSA
              </span>
              <Snackbar type={"info mt-s"} message={"💡  This work is restricted under NDA"}/>
            </div>
            <div className="project-detail-text">
              <p>
                <span className="label">The Context</span>
                With the aim of simplifying the whole experience, Cepsa hired us for updating and making a more focused company intranet.
              </p>
              <p>
                <span className="label">My Role</span>
                A dedicated team of UX Designers, Front end developers, UX Writers, a Product Owner and a designer. I was in charge of defining the visual languaje, a complex UI design system and interactions.
              </p>
              <span className="label">Main goals</span>
              <ul>
                <li>Successfully defined a visual language and a scalable design system</li>
                <li>Helped to define & design a powerfull search engine</li>
                <li>Improved overall discoverabillity of the most valulable content, reducing the % of end pages.</li>
                <li>Designed a customizable & highly scalable app ecosystem which helped focusing on main tasks</li>
              </ul>
              <blockquote>
                Now it´s cleaner and more organized. Highly intuitive and you can customize it to fit your day to day work.
                <br></br>
                <b>—Congratulations to the whole team!</b>
              </blockquote>
            </div>
          </div>

        </div>
      </div>

      <Banner/>

    </div>
  );
}
export default content
