import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';

import {ReactComponent as DrawIcon} from '../icons/draw.svg';


class App extends Component {

  render() {

    return (
      <div className={`app-wrapper page-app page-home theme-${this.props.theme}`}>

        <Header status={this.props.status}/>

        <div className="global">

          <section className="section-title mt-xl">
            <div className="container">
              <h1 className="big-header"><span className="drawing-icon">Hola! 👋<DrawIcon/></span></h1>
              <p className="big-description project-title-in">
                I'm a product-driven folk, tech minded & very team player, and I work to understand user problems and design workflows, interactions, and visual interfaces.
                <br></br>
                Thanks for passing by!
              </p>

            </div>
          </section>


          <section className="section-cases project-content-in-bottom">
            <div className="container mt-xl mb-xl">
              <h2 className="big-header">Selected</h2>
              <p className="big-description">
                Below is a small selection of works I've been a part of over the past decade. They represent my interest on product thinking, accesibility and inclusion, design systems, coding & UI design.
                <br></br>
              </p>
            </div>
            <div className="container container-xl">
              <div className="grid section-cases-grid mt-l">

                <Link to="/project/KoaMindset" className="grid__item width-8/12 width-12/12@m section-cases-item case-mindset">
                  <h3 className="section-cases-item-title">Koa Mindset</h3>
                  <div className="section-cases-item-label">2021 - 2022 · Product Design, Design Systems</div>
                  <p className="section-cases-item-description">Helping people improve their mood and wellbeing.</p>
                  <picture className="flex">
                    <img className="w-100" src={`${process.env.PUBLIC_URL}/img/projects/mindset/mindset-1.png`} alt="Koa Health Mindset"/>
                  </picture>
                </Link>
                
                <Link to="/project/KoaFoundations" className="grid__item width-4/12 width-12/12@m section-cases-item bg-fou">
                  <h3 className="section-cases-item-title title-small">Koa Foundations</h3>
                  <div className="section-cases-item-label">2022 · Product Design, Accesibility</div>
                  <p className="section-cases-item-description">No internet? We cover you. Designing for connectivity inclusion.</p>
                  <picture className="flex">
                    <img className="auto" src={`${process.env.PUBLIC_URL}/img/projects/foundations/fou-offline.png`} alt="Koa Foundations"/>
                  </picture>
                </Link>
                

                <Link to="/project/movibeta" className="grid__item width-7/12 width-12/12@m section-cases-item case-movibeta">
                  <h3 className="section-cases-item-title">Movibeta</h3>
                  <div className="section-cases-item-label">2020 · Product Design, Design Systems</div>
                  <p className="section-cases-item-description">A SaaS platform built to help filmmakers submitting their films to the best film Festivals around the globe.</p>
                  <picture className="flex">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-cover.png`} alt="Movibeta"/>
                  </picture>
                </Link>

                <Link to="/project/companion"  className="grid__item width-5/12 width-12/12@m section-cases-item case-companion">
                  <h3 className="section-cases-item-title">Companion</h3>
                  <div className="section-cases-item-label">2017 · Product Design</div>
                  <p className="section-cases-item-description">Never walk home alone. Research and design explorations for an on-demand personal security app solution.</p>
                  <picture className="flex">
                    <img className="" src={`${process.env.PUBLIC_URL}/img/projects/companion/@companion-home.png`} alt="companion"/>
                    <img className="" src={`${process.env.PUBLIC_URL}/img/projects/companion/@companion-destination.png`} alt="companion"/>
                  </picture>
                </Link>

                <Link to="/project/guia hedonista" className="grid__item width-5/12 width-12/12@m section-cases-item case-guia-hedonista">
                  <h3 className="section-cases-item-title">Guía Hedonista</h3>
                  <div className="section-cases-item-label">2018 · Product Design</div>
                  <p className="section-cases-item-description">A curated selection of restaurants that brings the best local gastronomy.</p>
                  <picture className="flex">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/@gh-home.png`} alt="guiahedonista"/>
                    <img src={`${process.env.PUBLIC_URL}/img/projects/guiahedonista/@gh-detail.png`} alt="guiahedonista"/>
                  </picture>
                </Link>

                <Link to="/project/keepkee" className="grid__item width-7/12 width-12/12@m section-cases-item case-keepkee">
                  <h3 className="section-cases-item-title">Keepkee</h3>
                  <div className="section-cases-item-label">2019 · UI Design, Visual language, Copywriting, Open Source</div>
                  <p className="section-cases-item-description">There's so much to remember. We password manage. You forget about the tricky things.</p>
                  <picture className="flex-justify">
                    <img className="" src={`${process.env.PUBLIC_URL}/img/projects/keepkee/keepkee-splash.png`} alt="keepkee"/>
                    <img className="" src={`${process.env.PUBLIC_URL}/img/projects/keepkee/keepkee-init.png`} alt="keepkee"/>
                  </picture>
                </Link>
                <div className="grid__item width-6/12 width-12/12@m">
                  <Link to="/project/hooptap" className="section-cases-item case-hooptap dl-b h-auto">
                    <h3 className="section-cases-item-title">Hooptap</h3>
                    <div className="section-cases-item-label">2012 - 2015 · Product Design</div>
                    <p className="section-cases-item-description">Design in a business transformation process. From customized B2B projects to product philosophy.</p>
                    <picture className="flex">
                      <img className="img-fit" src={`${process.env.PUBLIC_URL}/img/projects/hooptap/hooptap-main.png`} alt="hooptap"/>
                    </picture>
                  </Link>
                 
                 {/* <Link to="/project/dulcesol" className="section-cases-item case-dulcesol dl-b mt-24">
                    <h3 className="section-cases-item-title">Dulcesol</h3>
                    <div className="section-cases-item-label">Visual language, Design Systems</div>
                    <p className="section-cases-item-description">A design system for building robust consumer facing app's.</p>
                    <picture className="flex">
                      <img className="img-fit" src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/dulcesol-main.png`} alt="dulcesol"/>
                    </picture>
                  </Link> */}

                </div>

                <a href="https://favbox.netlify.app/feed" target="_blank" rel="noopener noreferrer" className="grid__item width-6/12 width-12/12@m section-cases-item case-favbox arrow-external mb-20">
                  <h3 className="section-cases-item-title">Favbox</h3>
                  <div className="section-cases-item-label">2020 · Founder, Product, Visual language, UI Development</div>
                  <p className="section-cases-item-description">Imagine to know what your industry leaders are reading or watching? Now it's possible. Favbox is a place to discover new perspectives and share your own.</p>
                  <picture className="flex">
                    <img src={`${process.env.PUBLIC_URL}/img/projects/favbox/@favbox-onboarding.png`} alt="favbox"/>
                    <img src={`${process.env.PUBLIC_URL}/img/projects/favbox/@favbox-init.png`} alt="favbox"/>
                    <img src={`${process.env.PUBLIC_URL}/img/projects/favbox/@favbox-profile.png`} alt="favbox"/>
                  </picture>
                </a>

              </div>
            </div>
          </section>



          <section className="section-latest-projects mt-xl">
            <div className="container">

              <h2 className="big-header">And lately...</h2>

              <div className="grid mt-l latest-project">
                <div className="grid__item width-8/12 width-12/12@m latest-project-story">
                  <div className="latest-project-story-content">
                    <Link to="project/KoaHealth"><h2 className="latest-project-title mb-s">Making life easier for design team folks 🦄</h2></Link>
                    <p className="big-text">Working on design ops initiatives to help the design team move faster at scale. Taken together, these incremental improvements not only help to sync our cross-functional teams, but also free up designers to focus on their craft and creativity.</p>
                    <span>
                      2020 - 2022 · Product designer at Koa Health
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid mt-l latest-project">
                <div className="grid__item width-8/12 width-12/12@m latest-project-story">
                  <div className="latest-project-story-content">
                    <Link to="project/ryanair"><h2 className="latest-project-title mb-s">I've been improving booking flows ✈️</h2></Link>
                    <p className="big-text">On some very important customer facing projects which resulted in significant revenue increases for the business.</p>
                    <span>
                      2019 · Product designer at Ryanair
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid mt-l latest-project">
                <div className="grid__item width-8/12 width-12/12@m latest-project-story">
                  <div className="latest-project-story-content">
                    <Link to="project/cepsa"><h2 className="latest-project-title mb-s">Helping employees to have a focused intranet 👩🏻‍💼</h2></Link>
                    <p className="big-text">Focusing on solving specific day-to-day problems, with an app ecosystem & a powerful search experience.</p>
                    <span>
                      2019 · Design consultant at The cocktail for CEPSA
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid mt-l latest-project">
                <div className="grid__item width-8/12 width-12/12@m latest-project-story">
                  <div className="latest-project-story-content">
                    <Link to="project/bbva"><h2 className="latest-project-title mb-s">Working on the future of traditional banking 💸</h2></Link>
                    <p className="big-text">In projects which take advantage of customer data to improve your relationship with money.</p>
                    <span>
                      2018 · Design consultant at The cocktail for BBVA
                    </span>
                  </div>
                </div>
              </div>

              

            </div>
          </section>

          <Footer className="mt-xl bg-soft-grey"/>

        </div>
      </div>
    );
  }
}

export default App;
