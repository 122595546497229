import React from "react";
import history from '../controllers/history';

import RyanairProject from '../projects/Ryanair';
import CepsaProject from '../projects/Cepsa';
import BbvaProject from '../projects/Bbva';
import GuiaHedonista from '../projects/GuiaHedonista';
import Companion from '../projects/Companion';
import Hooptap from '../projects/Hooptap';
import Dulcesol from '../projects/Dulcesol';
import Keepkee from '../projects/Keepkee';
import Movibeta from '../projects/Movibeta';
import KoaMindset from '../projects/KoaMindset';
import KoaFoundations from '../projects/KoaFoundations';
import KoaHealth from '../projects/KoaHealth';

export const getProjectData = (projectName) => {
  switch(projectName){
    case 'ryanair':
      return {
        name        : 'ryanair',
        isProtected : false,
        key         : 'RY2020',
        template    : <RyanairProject/>
      }

    case 'cepsa':
      return {
        name        : 'cepsa',
        isProtected : false,
        key         : 'CP2019',
        template    : <CepsaProject/>
      }

    case 'bbva':
      return {
        name        : 'bbva',
        isProtected : false,
        key         : 'BBVA2019',
        template    : <BbvaProject/>
      }

    case 'guia hedonista':
      return {
        name        : 'guia hedonista',
        isProtected : false,
        key         : '',
        template    : <GuiaHedonista/>
      }

    case 'companion':
      return {
        name        : 'companion',
        isProtected : false,
        key         : '',
        template    : <Companion/>
      }

    case 'hooptap':
      return {
        name        : 'hooptap',
        isProtected : false,
        key         : '',
        template    : <Hooptap/>
      }

    case 'dulcesol':
      return {
        name        : 'dulcesol',
        isProtected : false,
        key         : '',
        template    : <Dulcesol/>
      }

    case 'keepkee':
      return {
        name        : 'keepkee',
        isProtected : false,
        key         : '',
        template    : <Keepkee/>
      }

    case 'movibeta':
      return {
        name        : 'movibeta',
        isProtected : false,
        key         : '',
        template    : <Movibeta/>
      }
    case 'KoaMindset':
      return {
        name        : 'KoaMindset',
        isProtected : false,
        key         : '',
        template    : <KoaMindset/>
      }
    case 'KoaFoundations':
      return {
        name        : 'KoaFoundations',
        isProtected : false,
        key         : 'FOU',
        template    : <KoaFoundations/>
      }

    case 'KoaHealth':
      return {
        name        : 'KoaHealth', //CASE STUDY POST
        isProtected : false,
        key         : '',
        template    : <KoaHealth/>
      }

    default:
      return 'notfound'
  }
}



export const authenticate = (projectName, pass) => {
  //console.log('authenticate',projectName, pass)
  if(!projectName || !pass){
    history.push({
      pathname: '/project/'+projectName,
      state: {
        isAuth  : false,
        type    : 'error',
        message : '👽  Something happened'
      }
    })
    return
  }

  if( getProjectData(projectName).key === pass ){
    history.push({
      pathname: '/project/'+projectName,
      state: {
        isAuth  : true,
        type    : 'success',
        message : '👌  Passwords match'
      }
    })
  }
  else{
    history.push({
      pathname: '/project/'+projectName,
      state: {
        isAuth  : false,
        type    : 'error',
        message : '👽  Try again, please'
      }
    })
  }
};
