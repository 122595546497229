import React, { Component } from "react";

import Header from '../components/header';
import Footer from '../components/footer';
import Learnings from '../content/learnings';

class About extends Component {

  render() {
    
    return (
        <div className={`app-wrapper page-about theme-${this.props.theme}`}>

          <Header status={this.props.status}/>

          <div className="global">

            <section className="section-title mt-xl">
              <div className="container">
                <img src={`${process.env.PUBLIC_URL}/img/me-cap.jpg`} alt="Me" className="max-h-m me project-title-in"/>
                <h1 className="big-header"><span className="drawing-icon project-title-in">It's Carlos</span></h1>
                <p className="big-description no-ml project-content-in-bottom">
                  I design by day 🌞, code by night 🌚, and snorkel 🐠 in sunny days, a bit yogui 🧘🏻‍♂️ and aspiring mountain climber ⛰️ since lockdown times.
                  <br></br><br></br>
                  Team player, I partner with engineering, product, and business folks, to find elegant but practical solutions.
                  <br></br><br></br>
                  Following a process of understanding users and industry needs, experimenting and iterating to a solution, I help in product design, including thinking out of the box, prototyping, validation, user experience, interaction and visual design.
                </p>

                <div className="section-skills mt-l project-content-in-bottom">
                  <div className="container-xs grid oauto">
                        <div className="grid__item width-6/12">
                          <p className="text-bold">Foundational skills</p>
                          <div className="as-p">
                            <ul class="list-square">
                              <li>Collaboration</li>
                              <li>Problem-solving</li>
                              <li>Detail-oriented</li>
                              <li>Growth mindset</li>
                              <li>Ownership</li>
                              <li>Emotional intelligence</li>
                              <li>Multidisciplinary attitude</li>
                              <li>Sustainable work ethics</li>
                            </ul>
                          </div>
                        </div>
                        <div className="grid__item width-6/12">
                          <p className="text-bold">Expertise</p>
                          <div className="as-p">
                            <ul class="list-square">
                              <li>Interaction and UI design</li>
                              <li>System thinking</li>
                              <li>Visual design</li>
                              <li>Accessibility</li>
                              <li>Design Systems</li>
                              <li>Documentation</li>
                              <li>Information Architecture</li>
                              <li>User research</li>
                            </ul>
                          </div>
                        </div>
                    </div>
                </div>


              </div>
            </section>

            <Learnings />

          </div>

          <Footer/>

        </div>
      );
    }
  }

  export default About;
