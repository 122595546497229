import React, { Component } from "react";
import SocialList from './socialList';

import {ReactComponent as DrawArrow} from '../icons/draw-arrow.svg';

class Footer extends Component {

  render() {

    return (

      <footer className={`section-footer pt-xl pb-xl ${this.props.className}`}>
        <div className="container text-center">
          <h4>Wow! you reached the end.</h4>
          <p className="cl-grey">
            Want more? There’s more to the conversation. You can <a href="mailto:carlosmarchinfo@gmail.com" target="blank" className="cl-grey underline-hover" rel="noopener noreferrer">get in touch</a> & surf the interwebs with me.
          </p>
          <SocialList className="pt-m auto inline-block"/><DrawArrow/>
          <p className="pt-m auto small-text">© {new Date().getFullYear()} Made with 🍊 in Valencia & written in React</p>
        </div>
      </footer>

    );
  }
}

export default Footer;
