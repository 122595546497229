import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Koa Foundations.<br></br><span>No internet? We cover you.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m bg-soft-grey pt-l pb-l">
          <img src={`${process.env.PUBLIC_URL}/img/projects/foundations/fou-offline-01.png`} className="text-center" alt="Koa Foundations"/>
          <div className="caption">What we wanted to avoid.</div>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The context</div>
          <p className="text-bold">
            How would our world be different if we were optimistic about the possibility of fast and reliable Internet connectivity, but did not take it for granted?          </p>
          <p>
            Even as we transition from 4G to 5G, according to the Ericsson Mobility Report, there are still a lot of areas where mobile networks are slow or simply not available. Nearly 1.6 billion people around the world live outside the range of mobile internet networks. Millions more bounce in and out of network connectivity when on-the-go.
          </p>
          <blockquote>
            Most apps start with an incorrect assumption — that offline is, by default, an error state and they stop users from doing anything. Instead, we should think from user’s point of view and allow them to finish their goals.
          </blockquote>
          <p>
            Depending on the location, the internet connection might vary from WiFi to 4G, 3G to 2G to no internet at all, and <strong>at Koa Health we seek to better cater for a large number of people worldwide with technical barriers to mental health.</strong>
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Use cases</div>
          <p className="text-bold">
            Removing technical barriers to mental health
          </p>
          <p>
          There are plenty of reasons you may not have internet or a slow connection:
          </p>
          <ul>
            <li>Poor or no coverage in area</li>
            <li>Can’t afford a data plan for your phone.</li>
            <li>On transportation with intermittent connectivity</li>
            <li>On a flight</li>
            <li>On extreme weather conditions</li>
            <li>Internet connection is managed by a third party and it will be active or inactive</li>
            <li>And an extensive etc</li>
          </ul>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The Challenge</div>
          <p className="text-bold">The anxiety of losing the work done</p>
          <p>When we introduced <strong>Multidevice feature in Foundations</strong> a new set of user scenarios appeared. The ones that worried the team more were related to the anxiety of losing the work done in the app while jumping from one platform to another, specially when working offline and joining from other connected online device.</p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Considerations</div>
          <p className="text-bold">Guiding principles</p>
          <p>
            When kicking off the initiative we were at good starting point: 
          </p>
          <ul className="mt-s">
            <li>Designing for offline first ✅</li>
            <li>Don't block content ✅</li>
            <li>Provide an offline experience by default ✅</li>
          </ul>
          <p className="mt-m mb-m">
            Then, while doing <strong>product discovery</strong> and figuring out what we were trying to build, we reasearched and defined a set of best practices that would help us to build the best experience even while in worst connectivity conditions. And we we realized that we were missing a set of guiding principles that were critical with all the new scenarios:
          </p>
          <ul>
            <li className="bold">👀 Clearly indicate offline functionality</li>
            <li className="bold">🔔 Notify users when connection is restored</li>
            <li className="bold">📱 Update the UI to reflect the current contextual state</li>
            <li className="bold">📴 Educate the user so they understand what the offline model is</li>
            <li className="bold">💻 Make experiences transferable from one device to another</li>
            <li className="bold">🔄 When the connection is reestablished sync the progress</li>
            <li className="bold">🛟 Provide sense of safety and not overburden them with messaging</li>
            <li className="bold">👅 Use simple, concise language</li>
          </ul>
          <p classname="mt-m">
            As a result when we started envisioning the initiative we wanted to check every best practice and principle agreed on the discovery.          
          </p>
        </div>

        <div className="container grid section-cases-grid mt-l mb-l">

          <div className="grid__item width-6/12 text-center">
            <img className="br-m" src={`${process.env.PUBLIC_URL}/img/projects/foundations/fou-offline-02.png`} alt="Offline" />
            <div className="caption">Contextual once-in-a-lifetime message for indicating what the offline model is.</div>
          </div>

          <div className="grid__item width-6/12">
            <video muted autoPlay loop webkit-playsinline playsinline className="fou-vid br-m">
              <source src={`${process.env.PUBLIC_URL}/img/projects/foundations/fou-offline-vid.mov`} type="video/mp4" />
            </video>
            <div className="caption">Instead of being transparent when offline, we clearly indicate it without blocking the experieence.</div>
          </div>

        </div>
          


        <div className="container container-xs mt-l">
          <div className="label">Results</div>
          <p className="text-bold">Inclusive by default, understandable and seamless</p>
          <p>
          We ended up enhancing the application to the next level by improving accessibility for those with limited or inexistent connectivity, providing sense safety, information transparency, control, and awareness.
          </p>
          <blockquote>Offline design is an essential part of understanding and responding to users’ needs.<br></br> Sussana Zarasky</blockquote>
        </div>


      </div>

    </div>

    <Footer />
    <Banner />
  </div>

  );
}
export default content