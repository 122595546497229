import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          A new platform.<br></br><span>A new era for classic donuts.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m">
          <div className="container">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/dulcesol-main.png`} className="text-center" alt="Dulcesol"/>
          </div>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">THE CONTEXT</div>
          <p className="text-bold">
            The need of an ecosystem.
          </p>
          <p>
            Dulcesol partnered with VG to think about the design of their ecommerce site. They needed to find a way to showcase their line of products that would work seamlessly across all screen sizes. And our approach was to use atomic design as the design methodology.
          </p>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">Atomic Design</div>
          <p className="text-bold">
            Atomic Design as a methodology for product design.
          </p>
          <p>
            Atomic design means creating a system of pieces that can come together to create elements and templates we can reuse over and over again.
          </p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/atomic-design.png`} alt="Atomic Design" />
          <div className="caption">Atomic design core philosophy.</div>
          <br></br>
          <p>
            By creating modular, repeatable elements and patterns we can exponentially speed up our design and development process. Not only by how fast it is to build but also how quick it is to make changes across the system.
          </p>
          <blockquote>
            Atomic design isn’t about designing a single instance or page, it’s about designing with the big picture in mind — it’s about designing the core of the system.
            <b><a href="https://medium.com/re-write/the-unicorn-workflow-design-to-code-with-atomic-design-principles-and-sketch-8b0fe7d05a37">—Brad Forst</a></b>
          </blockquote>
          <p>
            Spend a little more time thinking through your core and gain an incredible amount of time back as the process unfolds. Some of the benefits are:
          </p>
          <ul>
            <li>It provides <strong>consistency</strong> in the user interface.</li>
            <li><strong>Modular and scalable</strong> designs.</li>
            <li>It offers <strong>flexibility and efficiency</strong>.</li>
            <li>It is <strong>predictable</strong>, which makes easier to use.</li>
            <li><strong>Time saver</strong>, because it reduces production times.</li>
          </ul>
        </div>


        <div className="fullwidth mt-l pt-m pb-m bg-soft-grey">
          <div className="container">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/unidad-minima.png`} alt="unidad-minima" />
            <div className="caption">Product component as the minimum unit.</div>
          </div>
        </div>


        <div className="container container-xs mt-l">
          <div className="label">The Process</div>
          <p className="text-bold">
            Starting with atoms.
          </p>
          <p>
            The idea of Atomic Design is to begin with common raw material (atoms) with which we can build the rest of the project. This visual language must be strong, easy to build, and free itself from the context on which it is going to be displayed. It has to be able to work on every screen!
          </p>
        </div>


        <div className="fullwidth mt-l pt-m pb-m bg-soft-grey">
          <div className="container">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/unidad-minima-grid-raw.png`} alt="grid" />
            <div className="caption">Possible variations of a generic component</div>
          </div>
        </div>




        <div className="fullwidth mt-l pt-m pb-m bg-soft-grey">
          <div className="container">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/block-macarons.png`} alt="component" />
            <div className="caption">Sample of a block including real examples of a component use cases.</div>
          </div>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">Conclusion</div>
          <p className="text-bold">
            The part and the whole
          </p>
          <p>
            Atomic Design provides semantic rules and principles of organization for interface elements. One of the really interesting things building a system of components with Atomic Design is that we are conscious of creating a set of elements that depend on each other.
          </p>
        </div>

        <div className="fullwidth mt-l text-center pt-m pb-m bg-soft-grey">
          <div className="container container-xs">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/home.jpg`} alt="Dulcesol Website" className="has-shadow" />
            <div className="caption">Home layout with different blocks.</div>
          </div>
        </div>

        <div className="fullwidth mt-l text-center pt-m pb-m bg-soft-grey">
          <div className="container container-xs">
            <img src={`${process.env.PUBLIC_URL}/img/projects/dulcesol/ecosystem.jpg`} alt="Dulcesol Website" className="has-shadow" />
            <div className="caption">Family products detail view. Header, filters, and cards.</div>
          </div>
        </div>


      </div>

    </div>

    <Footer />
    <Banner />
    
  </div>

  );
}
export default content