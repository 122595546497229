import React, { Component } from "react";
import { NavLink  } from 'react-router-dom';

class HeaderNav extends Component {
  
  render() {
    
    return (
      <nav className="header-menu ml-m">
        <ul className="header-menu-nav flex-center">
          <li className="no@m"><NavLink to="/" exact activeClassName="selected" className="header-menu-nav-item button-nav">Works</NavLink>  </li>
          <li><NavLink to="/about" exact activeClassName="selected" className="header-menu-nav-item button-nav">About</NavLink>  </li>
          {
          this.props.status 
          ? <li><a href="mailto:carlosmarchinfo@gmail.com" target="blank" className="pill flex-center ml-xs no@m"><span>{this.props.status}</span></a></li> 
          : <li><a href="mailto:carlosmarchinfo@gmail.com" target="blank" className="header-menu-nav-item button-nav" rel="noopener noreferrer">Let's talk</a></li>
          }
        </ul>
      </nav>
    );
  }
}

export default HeaderNav;
