import React, { Component } from "react";
import { withRouter } from 'react-router-dom'

import Message from '../components/message';
import history from '../controllers/history';
import * as projectController from '../controllers/projectController';


class Protected extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName   : this.props.projectName
    };
  }

  passwordChangeHandler = (event) => {
    this.setState({password: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //AUTH
    projectController.authenticate(this.state.projectName, this.state.password)
  }


  componentDidMount(){
    //Clear history state messages
    if (history.location.state && history.location.state.message) {
        let state = { ...history.location.state };
        delete state.message
        delete state.type
        history.replace({ ...history.location, state });
    }
  }


  render() {
    
    return (
        <div className="protected text-center project-title-in">
          <div className="protected-icon"></div>
          <h4 className="protected-title alt-font">NDA Protected</h4>
          <p className="protected-text">{this.props.projectName || 'This' } is currently being developed.</p>
          <form onSubmit={this.handleSubmit} className="protected-form inflex">
            <input
              placeholder="Password"
              onChange={this.passwordChangeHandler}
              required/>
            <button><span>Submit</span></button>
          </form>
          {this.props.location?.state?.message
            ? <Message type={this.props.location?.state?.type} message={this.props.location?.state?.message}/>
            : ''
          }
        </div>


    );
  }
}

export default withRouter(Protected);
