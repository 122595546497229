import React, { Component } from "react";

class Snackbar extends Component {
  render() {
    return (

      <div className={`snackbar snackbar-${this.props.type} ${this.props.className}`}>
        {this.props.message}
      </div>

    );
  }
}

export default Snackbar;

