import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Keepkee.<br></br><span>So much to remember.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-keepkee text-center displace-xl mb-m">
          <img src={`${process.env.PUBLIC_URL}/img/projects/keepkee/@keepkee-site.png`} className="text-center p-m" alt="Keepkee Site"/>
        </div>
        <div className="container container-xs mt-l">
          <div className="label">The context</div>
          <p>
            I've been using <a href="https://keeweb.info/" target="_blank" rel="noopener noreferrer">Keeweb</a>, an open source password manager, for a while, and wanted to collaborate with it. Exploring some rebranding, product thinking, UI design, and Ux writing, for the joy of contributing to open source.
          </p>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Main goals</div>
          <ul>
            <li>Improve the login experience</li>
            <li>Focus on main user flow experience, simplify it without hurting the product</li>
            <li>Improve understandability, readability and content digest</li>
            <li>Make meaningful information pop</li>
            <li>Add delight ;)</li>
          </ul>
        </div>

        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/keepkee/@keepkee-login-overlook.png`} alt="Login overlook" />
            <div className="caption">Onboarding overlook</div>
        </div>

        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/keepkee/@keepkee-access-overlook.png`} alt="Access overlook" />
            <div className="caption">Access overlook</div>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">Main Screens</div>
          <p>
            Main view is structured in two parts. The first one belongs to recent opened files, visually represented by cards. After comes the full list of items grouped in folders or just single.
          </p>
          <p>
            The idea behind the cards is to tag popular services with a logo (or icon), a category and a predominant brand color. Then build it dinamically when a new kee matches the service ID.
          </p>
        </div>

        <div className="fullwidth mt-l text-center pt-m p-b-40">
            <img src={`${process.env.PUBLIC_URL}/img/projects/keepkee/@keepkee-screens-overlook.png`} alt="Screens overlook" />
            <div className="caption">Main flow overlook</div>
        </div>


      </div>

    </div>

    <Footer />
    <Banner />
  </div>

  );
}
export default content