//Returns last second part of the url
export const getUrlProjectName = () => {
  return decodeURIComponent(window.location.pathname.split("/").pop());
}


export const closeStatusUI = () => {
  sessionStorage.setItem('statusHide', true );
}

export const getStatusUI = () => {
  return JSON.parse(sessionStorage.getItem('statusHide'));
}

export const showBanner = () => {
  if( sessionStorage.getItem('statusHide')){
    return false
  }else{
    return true
  }
}
