import React from "react";
import Banner from '../content/banner';
import Snackbar from '../components/snackbar';


const content = () => {
  return (
    <div className="project-detail">

      <div className="container">

        <div className="project-detail-content grid vh100">

            <div className="grid__item width-5/12 width-12/12@m flex bg-bbva p-l br-l">
              <div className="circle-logo">
                <img src={`${process.env.PUBLIC_URL}/img/projects/bbva/bbva.svg`} alt="bbva"/>
              </div>
            </div>

            <div className="grid__item width-1/12 width-12/12@m no@m"></div>

            <div className="grid__item width-6/12 width-12/12@m pt-m">
              <h3 className="project-detail-title">
                BBVA.<br></br><span>Improving your relationship with the brands you buy.</span>
              </h3>
              
              <div class="project-title-in">
                <br></br>
                <span class="cl-grey">
                  2018 · Design consultant at The cocktail for BBVA
                </span>
                <Snackbar type={"info mt-s"} message={"💡  This work is restricted under NDA"}/>
              </div>

              <div className="project-detail-text">
                <p>
                  <span className="label">The Context</span>
                  Partnered a small team of a Design Lead, a UX Lead, and a key stakeholder to strategically think and product design a innovative banking solution which take advantage of customer data.
                </p>
                <p>
                  <span className="label">My Role</span>
                  My role was to collaborate thinking strategically with the team, and propose UI Design solutions that responded to key insights.
                  Made use of the design system to design an app that changes your relationship with the brands you shop, and at the end the way you buy.
                </p>
                <p>
                  <span className="label">Main goals</span>
                  We defined & designed an innovative solution in short times, due to working with data insights and thinking strategically inside a highly collaborative environment.
                </p>
              </div>
            </div>

        </div>
      </div>

      <Banner/>

    </div>
  );
}
export default content