import React from "react";
import Footer from '../components/footer';
import Banner from '../content/banner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const content = () => {
  return (
  <div>
    <div className="project-detail">

      <div className="project-detail-title container mt-xl">
        <h1 className="project-title">
          Movibeta for filmmakers.<br></br><span>Oh, the film festivals you will discover.</span>
        </h1>
      </div>


      <div className="project-detail-content">

        <div className="bg-soft-grey text-center displace-xl mb-m bg-movibeta-soft">
          <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-main-home.png`} className="text-center" alt="Movibeta"/>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The context</div>
          <p className="text-bold">Helping filmmakers to connect with festivals.</p>
          <p>
            Movibeta is one of the first SaaS platforms built to help filmmakers to submit their films to the best film Festivals around the world. And it also helps festivals to manage all their film submissions.
          </p>
          <p>
            Movibeta is relatively a small business, very niche focused, but in 2020 and specially after lockdown experienced exponential growth with more than 2k new visits per week.
          </p>
        </div>

        <div className="fullwidth text-center mt-l">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-graph.png`} alt="Movibeta graph"/>
        </div>

        <div className="container container-xs mt-l">
          <div className="label">The Problem</div>
          <p className="text-bold">The need of driving the business growth.</p>
          <p>
            The current version was designed and developed about 10 years ago and it was <strong>not able to drive the growth of the business</strong>. The team wanted to refresh the platform with an improved UX to create a flexible platform which should allow to drive the business growth of the next few years.
          </p>
        </div>



        <div className="container container-xs mt-l">
          <div className="label">My role</div>
          <p className="text-bold">End-to-end from discovery to delivery.</p>
          <p>
            Throughout the project my role focused on understanding and strategizing how design could help us <strong>provide the best solution to create revamped site, for both public site & back-office</strong>.
          </p>
          <p>
            As a team we set goals and I worked on foundational research to better understand the problem space. We defined a framework for measuring UX success to help inform on design decisions, and tested our designs through the lens of which would help us successfully reach our goals.
          </p>
        </div>



        <div className="fullwidth text-center bg-movibeta-soft mt-l ov-h">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-public.png`} alt="Movibeta public" className="movibeta-img-rotate"/>
        </div>
        <div className="caption mt-s">Public site overview.</div>



        <div className="container container-xs mt-l mb-m">
          <div className="label">The process. Step by step (grab a ☕)</div>
          <p className="text-bold">A solid team process to get better solutions.</p>
          <p>
            Without being strict I've understood that a with a solid <strong>team process</strong> it´s easy to get better solutions. It doesn’t always work in a linear path, going from user research, to ux design, to ui design. Instead, it involves a lot of back and forth and buy-in from various collaborators and stake-holders. But looking at the big picture we drew a clear path.
          </p>
        </div>



        <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="container container-xs"><div className="label">Foundational research</div></div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="container container-xs">
                    At the very begining we discussed the company objectives and vision, OKR's and which KPI's should we focus on different areas and product verticals. Defined a simple HEART framework for <strong>measuring UX success</strong> and for focusing on accomplishing prioritized goals.
                  </p>
                  <div className="fullwidth mb-l text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-heart.png`} alt="Movibeta heart" />
                      <div className="caption">Movibeta HEART framework big picture.</div>
                  </div>
                  <p className="container container-xs">
                    My earliest research included <strong>collecting insights from quantitative data</strong>. Also <strong>interviewing key users to uncover their voice</strong>. The goal was to define friction points and spot opportunities for the business and for improving the experience.
                  </p>
                  <p className="container container-xs">
                    I also ran a satisfaction survey on task completion to rank the features folks most wanted to improve. Finally, I researched how competitors approached similar problems, and analyzed if there were some best practices I should apply.
                  </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="container container-xs"><div className="label">From problems to opportunities</div></div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="container container-xs">
                    From this foundational research, I identified and defined the user groups and their experiences. Defined 2 main groups of users with two subgroups each one. This helped us to <strong>frame user problems and opportunities</strong>. At the end I presented the results to the team, to have everyone aligned and be aware of the results.
                  </p>
                  <div className="fullwidth mb-l text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-users.png`} alt="Movibeta users" />
                      <div className="caption">Documenting the big picture of user insights.</div>
                  </div>
                  <p className="container container-xs">
                    I started by testing a few hypotheses on the old platform. This was crucial in informing the team, since it provided a number of initial data points, and qualitative insights that helped us make critical decisions as we moved forward. Based on these early insights, <strong>we were then able to formulate some initial affirmations</strong>:
                  </p>
                  <ul className="container container-xs">
                    <li>Homepage visitors have high intent to create or return to an account, but homepage lacked a clear value proposition.</li>
                    <li>General users, but specially complex user types -like juries, are experimenting performance issues, and the navigation model is hurting engagement.</li>
                    <li>Pricing model is not as competitive, and as desirable for creators.</li>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="container container-xs"><div className="label">Driving Proposals</div></div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="container container-xs">
                    We run multiple brainstorming sessions to explore different ideas and quickly sketched them on papers. The goal was to <strong>generate as many ideas as possible</strong>.
                  </p>
                  <p className="container container-xs">
                    We selected the best ones, discarded the non-driving proposals and I started working on the low-fidelity prototype of the new platform.
                    <br></br>
                    <br></br>
                    Explored the core architecture and navigation model for the public site, and also how the backoffice navigation is going to work for our different type of users.
                  </p>
                  <div className="fullwidth mt-m mb-s text-center">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-first-wireframes.png`} alt="Movibeta wireframes" className="no-s"/>
                      <div className="caption">Wireframes exploring the navigation model and switching accounts problem space.</div>
                  </div>
                  <p className="container container-xs">
                    We explored a sidebar navigation as a strong option. But after some tests we wanted to keep a fluid way of navigating between the public site and the backoffice and decided to go with an horizontal menu divided in two parts, left for public views and right for user options. This allowed us to create a seamless and solid experience for visitors, creators, festival admins and mixed users, who need to go from public to private views to check data and perform a few core tasks.
                  </p>
                  <p className="container container-xs">
                    From the solution choosed, we ran internal sessions to validate them and to be sure the user experience was first class. With all the feedback got, I could start creating an improved high-fidelity prototype.
                  </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="container container-xs"><div className="label">Creating, crafting and documenting</div></div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="container container-xs">
                    Started <strong>stablishing how the new movibeta is going to look like</strong>. I defined spacing, fonts sizes, and colors. At the end we had tokens, atoms, molecules and also defined components and page layouts.
                  </p>
                  <div className="fullwidth mb-l text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-ds.png`} alt="Movibeta design system" className="no-s"/>
                      <div className="caption">Design System overview.</div>
                  </div>
                  <p className="container container-xs">
                    This work was done thinking systematically while building at the same time a scalable design system based on atomic philosophy. This way of creating allowed us to rapidly build new flows, and change steps or screen details without hurting the global layout, interaction patterns or the components itselfs.
                  </p>
                  <p className="container container-xs">
                    Documenting user flows and tasks was also a tough task, done to avoid possible information gaps when developing the new experience.
                  </p>
                  <video muted autoPlay loop className="container mt-m mb-m text-center">
                    <source src={`${process.env.PUBLIC_URL}/img/projects/movibeta/Movibeta-Festival_filter-flow.mp4`} type="video/mp4" />
                  </video>
                  <div className="caption">Documenting festivals filtering flow.</div>

                  <div className="fullwidth mb-s text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-documentation.png`} alt="Movibeta documentation" />
                      <div className="caption">Documenting festivals filtering flow.</div>
                  </div>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="container container-xs"><div className="label">Measuring, testing, iterating, validating</div></div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="container container-xs">
                    We wanted to increase our user retention, one of our core objectives. When we looked into our user dropoff, we found that 30% of users reported difficulty switching between user types. We predicted that if users had a way to navigate through different profiles in a easy way, they would be more likely to user our product.
                  </p>
                  <p className="container container-xs">
                    At this point I run some additional user testing sessions to <strong>measure ratios of task success</strong> with different users and main tasks. I ran a task based test to measure usability success of testers who completed the mission via the expected path(s). The test was tailored to different user types (festivals & filmmakers) to undercover if the experience was a right fit for both.
                  </p>
                  <div className="fullwidth mb-l text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-heatmap-1.png`} alt="Movibeta heatmap" />
                      <div className="caption">User testing heatmap.</div>
                  </div>
                  <p className="container container-xs">
                    After each main task, I asked for satisfaction questions to measure inside an Opinion Scale from 1 to 10, being 1 not so easy and 10 very easy.
                  </p>
                  <div className="container container-xs mb-l text-center mt-l">
                      <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-survey.png`} alt="Movibeta survey" />
                      <div className="caption">User testing survey.</div>
                  </div>
                  <p className="container container-xs">
                     The user evaluations and task success ratios were incredibly positive, I made a few adjustments to improve a couple of flows. Our internal teams were excited about the new user experience with the platform.
                  </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>



        <div className="container container-xs mt-l">
          <div className="label">Impact</div>
          <p className="text-bold">Designing to impact metrics.</p>
          <p>
            Redesigning the public site meant not only modernizing an important part of our product but also <strong>delivering an inspirational narrative to those visiting with high intent</strong>. To better understand how the new platform performed, we made some qualitative research. The new user interface is perceived as slick and enjoyable to use by our own users. Visitors can find the value of the platform, so they are more likely to become users.
          </p>
          <p>
            From the insights extracted by usability tests and surveys, we are providing a revamped version of the product that:
          </p>
          <ul>
           <li>Creates awareness while navigating through main flows and provides a clear, ordered and scalable navigation model</li>
           <li>Helps to perform core tasks effectivelly.</li>
           <li>Improves global brand perception</li>
          </ul>
          <p>
            After the redesign, <strong>numbers are growing successfully</strong> while the new platform goes live. Users spend more time within the site, visit more pages, and are much more likely to return within a week.
          </p>
        </div>

        <div className="container container-m mt-l">
          <div className="circle-wrapper">

            <div className="circle-figure">
              <div className="circle">+14%</div>
              <div className="bold mt-s">Time spent in the platform</div>
            </div>
            <div className="circle-figure">
              <div className="circle">-12%</div>
              <div className="bold mt-s">Bounce rate</div>
            </div>
            <div className="circle-figure">
              <div className="circle">+10%</div>
              <div className="bold mt-s">Pageviews</div>
            </div>
          </div>
        </div>





        <div className="container container-xs mt-l">
          <div className="label">Learnings</div>
          <p className="text-bold">
            In addition to our findings from experiments and research, I also learned a few things.
          </p>
          <p>
            During this months of work I combined business requirements, tech constraints, quantitative and qualitative insights to create the best experience possible and help our users to perform better during their daily work.
          </p>
          <ul>
            <li>
              You need to have clear guidelines and alignment up front for what <strong>‘success means’</strong>. Product definition and deciding where to focus when designing can be difficult, but it's easy if you are aware of how success should look like and which achievements should we pursue looking at the big picture.
            </li>
            <li>
              <strong>Early insights from teams and foundational research</strong> help to make sure time and resources are well invested, focused and scoped before building.
            </li>
            <li>
              Building longer projects are at <strong>risk for scope</strong>. Making sure to clearly define requirements and hold yourself accountable to them.
            </li>
          </ul>
        </div>



        <div className="container container-xs mt-xl mb-s">
          <p className="text-bold text-center">Public site</p>
        </div>
        <div className="fullwidth text-center">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-public.png`} alt="Movibeta public" />
        </div>

        <div className="container container-xs mt-l mb-s">
          <p className="text-bold text-center">Backoffice for festivals</p>
        </div>
        <div className="fullwidth text-center">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-backoffice.png`} alt="Movibeta backoffice" />
        </div>


        <div className="container container-xs mt-l mb-s">
          <p className="text-bold text-center">Global settings</p>
        </div>
        <div className="fullwidth text-center">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-settings.png`} alt="Movibeta settings" />
        </div>


        <div className="container container-xs mt-l mb-s">
          <p className="text-bold text-center">Backoffice for creators</p>
        </div>
        <div className="fullwidth text-center">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-videos.png`} alt="Movibeta videos" />
        </div>


        <div className="container container-xs mt-l">
          <p className="text-bold text-center">Mobile screens</p>
        </div>
        <div className="fullwidth text-center">
            <img src={`${process.env.PUBLIC_URL}/img/projects/movibeta/movibeta-mobile.png`} alt="Movibeta Mobile" />
        </div>



      </div>

    </div>

    <Footer />
    <Banner />
  </div>

  );
}
export default content