import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Headernav from './headerNav';
import {ReactComponent as BackIcon} from '../icons/Back.svg';

class HeaderBack extends Component {

  render() {

    return (
        <header className={`header flex-center ${this.props.className || '' }`}>
          <div className="container flex-justify">
            <div className="back-button flex-center">
              <Link to="/" className="back-icon"><BackIcon /></Link>
              <div className="back-text alt-font ml-s cap">{this?.props?.projectName || 'Back Home'}</div>
            </div>
            <Headernav status={this.props.status}/>
          </div>
        </header>

    );
  }
}

export default HeaderBack;
